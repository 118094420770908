import React from "react";
import styles from "./ContactUs.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import swal from "sweetalert";
import submitSendmessage from "api/sendmessage";
import { useNavigate } from "react-router-dom";
function ContactUs() {
  const notifyLoading = () => toast.loading(t("Please wait..."));
  const notifyError = () => toast.error(t("please_complete_all_fields"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigator = useNavigate();

  const { t } = useTranslation();
  const onSubmitForm = async (messageDate) => {
    toast.dismiss();
    notifyLoading();

    console.log(messageDate);
    let submitResult = await submitSendmessage(messageDate);
    if (submitResult.status == 1) {
      console.log("saved");
      toast.dismiss();
      swal(t("company_thank_msg_contact_us"), "success").then((val) => {
        navigator("/");
      });
    } else {
      toast.dismiss();
      notifyError();
      console.log("failed");
    }
  };

  return (
    <section id={styles["contact-us"]}>
      <Container>
        <Row>
          <Col className="m-auto" md="10" xs="12">
            <div className={styles["contact-us__conetnt"]}>
              <h1 className={styles["contact-us__conetnt-title"]}>
                {t("contact_us")}
              </h1>
              <h4 className={styles["contact-us__conetnt-desc"]}>
                {t("contact_desc")}
              </h4>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className={`${styles["contact-us__field"]} row`}>
                  <label
                    className={`${styles["contact-us__field-label"]} col-sm-3 col-4`}
                    htmlFor="name"
                  >
                    {t("name")}

                    <span className={styles["contact-us__required"]}>*</span>
                  </label>
                  <input
                    className={`${styles["contact-us__field-input"]}`}
                    type="text"
                    id="name"
                    name="name"
                    size="lg"
                    {...register("name", {
                      required: t("please_add_name"),
                    })}
                  />
                  {errors.name && (
                    <span className={styles["contact-us__error-message"]}>
                      {errors.name?.message}
                    </span>
                  )}
                </div>
                <div className={`${styles["contact-us__field"]} row`}>
                  <label
                    className={`${styles["contact-us__field-label"]} col-sm-3 col-4`}
                    htmlFor="email"
                  >
                    {t("email")}
                    <span className={styles["contact-us__required"]}>*</span>
                  </label>

                  <input
                    className={`${styles["contact-us__field-input"]}`}
                    type="email"
                    id="email"
                    name="email"
                    size="lg"
                    {...register("email", {
                      required: t("please_add_email_address"),
                    })}
                  />
                  {errors.email && (
                    <span className={styles["contact-us__error-message"]}>
                      {errors.email?.message}
                    </span>
                  )}
                </div>
                <div className={`${styles["contact-us__field"]} row`}>
                  <label
                    className={`${styles["contact-us__field-label"]} col-sm-3 col-4`}
                    htmlFor="message"
                  >
                    {t("message")}
                    <span className={styles["contact-us__required"]}>*</span>
                  </label>
                  <textarea
                    rows="5"
                    className={`${styles["contact-us__field-input"]} 
                  ${styles["contact-us__field-input--textarea"]}`}
                    id="message"
                    name="message"
                    size="lg"
                    {...register("message", {
                      required: t("please_add_message"),
                    })}
                  />
                  {errors.message && (
                    <span className={styles["contact-us__error-message"]}>
                      {errors.message?.message}
                    </span>
                  )}
                </div>
                <div className={styles["contact-us_div_btn"]}>
                  <button className={`${styles['contact-us__btn']} btn btn-dark`} type="submit">
                    {t("send")}
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactUs;
