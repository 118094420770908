import React from 'react'
import styles from './Rate.module.css'
import {ReactComponent as Star} from 'assets/icons/star.svg'
import { useTranslation } from 'react-i18next'
function Rate({rateNum , NoUser}) {
  const {t} =useTranslation()
  return (  
    <div className={`${styles['star-cont']} d-flex align-items-center`}>
      <div className={styles['star-cont__icons-cont']}>
        <Star className={`${styles['star-cont__icon']} ${rateNum > 0 && styles['star-cont__icon--fill']}`}/>
        <Star className={`${styles['star-cont__icon']} ${rateNum > 1 && styles['star-cont__icon--fill']}`}/>
        <Star className={`${styles['star-cont__icon']} ${rateNum > 2 && styles['star-cont__icon--fill']}`}/>
        <Star className={`${styles['star-cont__icon']} ${rateNum > 3 && styles['star-cont__icon--fill']}`}/>
        <Star className={`${styles['star-cont__icon']} ${rateNum > 4 && styles['star-cont__icon--fill']}`}/>
      </div>
      {
        NoUser&&
      <span className={styles['star-cont__number']}>{rateNum} ( {NoUser} {t('user')})</span>
      }
    </div>
  )
}

export default Rate