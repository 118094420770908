import React from 'react'
import CommingSoonComp from 'components/CommingSoon/CommingSoonComp'
import {motion} from 'framer-motion'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';

function CommingSoon() {
  const {t} =useTranslation();
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{t('comming_soon')}</title>
        </Helmet>
        <CommingSoonComp/>
      </motion.div>
    </>
  )
}

export default CommingSoon