import React from 'react'
import  './AddCompanyContactInfo.css'
import styles from './AddCompanyContactInfo.module.css'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { emailPattern, linkPattern } from 'utils/features'
function AddCompanyContactInfo({ activeClass ,changeActiveState }) {
    const {t} =useTranslation()
    const notifyError = () => toast.error(t('please_complete_all_fields'));

    const { register ,formState:{errors}  ,trigger,getValues } =useFormContext()
    let goToNextStage=()=>{
        changeActiveState(3)
        window.scrollTo(0,0)
    }
    let goToPrevStage=()=>{
        changeActiveState(1)
        window.scrollTo(0,0)
    }

    let checkAllFieldsFirstThenNavigate =async ()=>{
        const result = await trigger(["phone","email_address","fax","facebook_link","twitter_link","instagram_link"
        ,"website_link"]);
        if(!result){
            notifyError()
            return;
        }
        goToNextStage()
    }
    
 
  return (
    <section className={`${styles['add-company__contact']} ${styles[activeClass]}`}>
        <Container>
            <h1 className={styles['add-company__contact-title']}>{t('contacts_information')}</h1>
            <div className={styles['add-company__contact-cont']}>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>
                        {t('phone_number')}
                        <span className={styles['add-company__required']}>*</span>
                    </label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        {/* <input type="text" className={`${styles['add-company__field-input']}`} 
                        {...register("phone",{required:t('please_add_phone')})}/> */}
                        
                        <PhoneInput className={`${styles['add-company__field-input']}`} 
                            // placeholder="Enter phone number"
                            name='phone'
                            defaultCountry="EG"
                            international
                            value={getValues().phone &&getValues().phone}
                            {...register("phone",{required:t('please_add_phone'),
                            validate :{
                                checkPhoneNumber :(value)=>{
                                    return isPossiblePhoneNumber(value) || t('please_add_correct_phone_number')
                                }
                            }
                        })}
                            />
                        {errors.phone && <span className={styles['add-company__error-message']}>{errors.phone?.message}</span>}
                    </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>
                        {t('email_address')}
                        <span className={styles['add-company__required']}>*</span>
                    </label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        <input type="email" className={`${styles['add-company__field-input']}`} 
                        {...register("email_address",{required:t('please_add_email_address'),
                        pattern:{
                            value:emailPattern,
                            message:t('please_add_valid_email')
                        }})}/>
                        {errors.email_address && <span className={styles['add-company__error-message']}>{errors.email_address?.message}</span>}
                    </div>
                </div>
                
                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>{t('fax_number')}</label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        {/* <input type="text" className={`${styles['add-company__field-input']}`}
                        {...register("fax")}/> */}
                        <PhoneInput className={`${styles['add-company__field-input']}`} 
                            name='fax'
                            defaultCountry="EG"
                            international
                            value={getValues().fax &&getValues().fax}
                            {...register("fax",{
                            validate :{
                                checkPhoneNumber :(value)=>{
                                    if(value ===''|| value==='+20'||value === value.split(' ')[0] ){
                                        return true;
                                    }else{
                                        return isPossiblePhoneNumber(value) || t('please_add_correct_fax_number')
                                    }
                                }
                            }
                        })}
                            />
                        {errors.fax && <span className={styles['add-company__error-message']}>{errors.fax?.message}</span>}
                    </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>{t('facebook_link')}</label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        <input type="url" className={`${styles['add-company__field-input']}`}
                        {...register("facebook_link",{
                        pattern:{
                            value:linkPattern,
                            message:t('please_add_valid_link')
                        }})}/>
                        {/* {...register("facebook_link",{required:t('please_add_facebook_link')})}/> */}
                        {errors.facebook_link && <span className={styles['add-company__error-message']}>{errors.facebook_link?.message}</span>}
                    </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>{t('twitter_link')}</label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        <input type="url" className={`${styles['add-company__field-input']}`}
                        {...register("twitter_link",{
                            pattern:{
                                value:linkPattern,
                                message:t('please_add_valid_link')
                            }})}/>
                        {/* {...register("twitter_link",{required:t('please_add_twitter_link')})}/> */}
                        {errors.twitter_link && <span className={styles['add-company__error-message']}>{errors.twitter_link?.message}</span>}
                    </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>{t('instagram_link')}</label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        <input type="url" className={`${styles['add-company__field-input']}`}
                        {...register("instagram_link",{
                            pattern:{
                                value:linkPattern,
                                message:t('please_add_valid_link')
                            }})}/>
                        {/* {...register("instagram_link",{required:t('please_add_instagram_link')})}/> */}
                        {errors.instagram_link && <span className={styles['add-company__error-message']}>{errors.instagram_link?.message}</span>}
                    </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>{t('website_link')}</label>
                    <div className='col-md-9 col-12 d-flex flex-column'>
                        <input type="url" className={`${styles['add-company__field-input']}`}
                        {...register("website_link",{
                            pattern:{
                                value:linkPattern,
                                message:t('please_add_valid_link')
                            }})}/>
                        {/* {...register("website_link",{required:t('please_add_website_link')})}/> */}
                        {errors.website_link && <span className={styles['add-company__error-message']}>{errors.website_link?.message}</span>}
                    </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <button type='button' className={`${styles['add-company__move-stage']} ${styles['add-company__move-stage--prev']}`} onClick={goToPrevStage}><span>{t('prev')}</span></button>
                    <button type='button' className={`${styles['add-company__move-stage']} ${styles['add-company__move-stage--next']}`} onClick={checkAllFieldsFirstThenNavigate}><span>{t('next')}</span></button>
                </div>
            </div>
        </Container>
    </section>
  )
}

export default AddCompanyContactInfo