import axiosConfig from 'features/axiosConfig'

async function getGoverments(countryID) {
    let response = {data:[],status:false}
    try{
        let res = await axiosConfig.get(`/location/${countryID}/government/`)
        response.status=true;
        response.data = res.data.result
        return response
    }catch(e){
        response.data = e.message
        return response
    }
}

export default getGoverments