import React from 'react'
import styles from './Category.module.css'
import src from 'assets/imgs/company1.jpg'
import { useTranslation } from 'react-i18next'
import Rate from '../Rate/Rate'
import { NavLink } from 'react-router-dom'
function Category() {
  const {t} = useTranslation()
  return (
    <div className={styles['category']}>
        <div className={styles['category__img-cont']}>
            <img src={src} alt='category' className={styles['category__img']}/>
        </div>
        <div className={styles['category__content']}>
            <NavLink to='/preview-company' className={styles['category__content-name']}>{t('company_name')}</NavLink>
            <p className={styles['category__content-cat']}>
                <span className={styles['category__cat-name']}>{t('categories')}</span>
                <span className={styles['category__cat-desc']}>{t('clothing')}</span>
            </p>
            <div className={styles['category__content__rate-cont']}>
              <Rate rateNum='3.4' NoUser='40'/>
            </div>
        </div>
    </div>
  )
}

export default Category