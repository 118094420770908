import axiosConfig from 'features/axiosConfig'

async function getSubCategoriesFormAPI(id) {
    let response = {data:[],status:false}
    try{
        let res = await axiosConfig.get(`/catalog/category/${id}/child/`)
        response.status=true;
        response.data = res.data.result
        return response
    }catch(e){
        response.data = e.message
        return response
    }
}

export default getSubCategoriesFormAPI