import Rate from 'components/Global/Rate/Rate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AddReview from '../AddReview/AddReview'
import LoginWithSocialMedia from '../LoginWithSocialMedia/LoginWithSocialMedia'
import styles from './PreviewComment.module.css'
function PreviewComment() {
    const {t} =useTranslation()
  return (
    <div className={styles['preview-company__company-review']}>
        <h2 className={styles['preview-company__review-title']}>{t('reviews')}</h2>
        <div className={styles['preview-company__review-comment']}>
            <div className={styles['preview-company__comment-info-cont']}>
                <h3 className={styles['preview-company__comment-name']}>{t('username')}</h3>
                <span className={styles['preview-company__comment-time']}>20 / 11 / 2022</span>
            </div>
            <Rate rateNum='5'/>
            <p className={styles['preview-company__comment']}>{t('comment_content')}</p>
        </div>
        <div className={styles['preview-company__review-comment']}>
            <div className={styles['preview-company__comment-info-cont']}>
                <h3 className={styles['preview-company__comment-name']}>{t('username')}</h3>
                <span className={styles['preview-company__comment-time']}>20 / 11 / 2022</span>
            </div>
            <Rate rateNum='2'/>
            <p className={styles['preview-company__comment']}>{t('comment_content')}</p>
        </div>
        <div className={styles['preview-company__review-comment']}>
            <div className={styles['preview-company__comment-info-cont']}>
                <h3 className={styles['preview-company__comment-name']}>{t('username')}</h3>
                <span className={styles['preview-company__comment-time']}>20 / 11 / 2022</span>
            </div>
            <Rate rateNum='3'/>
            <p className={styles['preview-company__comment']}>{t('comment_content')}</p>
        </div>
        <LoginWithSocialMedia/>
        <AddReview/>
    </div>
  )
}

export default PreviewComment