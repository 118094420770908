import React, { useEffect, useState } from "react";
import styles from "./AddCompanyForm.module.css";
import AddCompanyCompanyInfo from "../AddCompanyCompanyInfo/AddCompanyCompanyInfo";
import AddCompanyStages from "../AddCompanyStages/AddCompanyStages";
import { Container } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import AddCompanyContactInfo from "../AddCompanyContactInfo/AddCompanyContactInfo";
import AddCompanyAddressInfo from "../AddCompanyAddressInfo/AddCompanyAddressInfo";
import AddCompanyLoginInfo from "../AddCompanyLoginInfo/AddCompanyLoginInfo";
import Cookies from "js-cookie";
import submitCompany from "api/submitCompany";
import registerAddCompany from "api/registerAddCompany";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Loading from "components/Global/Loading/Loading";
function AddCompanyForm() {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const mehods = useForm({
    defaultValues:
      Cookies.get("companyValues") && JSON.parse(Cookies.get("companyValues")),
  });
  const [activeStage, setActiveStage] = useState(1);
  const ACTIVE_CLASS_NAME = "add-company__hidden";
  const watchAllFields = mehods.watch();
  const notifyLoading = () => toast.loading(t("Please wait..."));
  const notifyError = (msg) => toast.error(msg);
  const [isSubmitted, setIsSubmitted] = useState(false);
  let changeActiveState = (activeStagee) => {
    setActiveStage(activeStagee);
  };
  let registerPhaseApi = async (companyData) => {
    let companyInfo = {
      name_ar: companyData.company_ar,
      name_en: companyData.company_en,
      description_ar: companyData.description_ar,
      description_en: companyData.description_en,
      authority_number: companyData.trading_reg,
      tax_id: companyData.tax_iD,
      city_id: companyData.city,
      address_ar: companyData.address_ar,
      address_en: companyData.address_en,
      latitude: companyData.latitude,
      longitude: companyData.longitude,
      category_id: companyData.sub_categories,
      logo_url: companyData.logo[0],
      cover_url: companyData.cover[0],

      contact: {
        phone_number: companyData.phone,
        fax_number: companyData.fax,
        email_address: companyData.email_address,
        facebook_url: companyData.facebook_link,
        twitter_url: companyData.twitter_link,
        instagram_url: companyData.instagram_link,
        website_url: companyData.website_link,
      },
      user: {
        name: companyData.account_name,
        email: companyData.account_email,
        password: companyData.password,
      },
    };

    toast.dismiss();
    notifyLoading();
    // console.log(companyInfo)
    let companyInfoRes = await submitCompany(companyInfo);
    if (companyInfoRes.status) {
      console.log(companyInfoRes.data);
      Cookies.remove("companyValues");
      toast.dismiss();
      swal(
        t("company_added_successfully"),
        t("company_thank_msg"),
        "success"
      ).then((val) => {
        setIsSubmitted(true);
        Cookies.remove("companyValues");
        navigator("/");
      });
    } else {
      toast.dismiss();
      const keys = Object.values(companyInfoRes.data);
      console.log(keys);
      keys.map((errorName) =>
        Object.values(errorName).map((item) => notifyError(item[0]))
      );
      // keys.forEach((key, index) => {
      //   // const errorKeys = Object.values(companyInfoRes.data[key]);
      //   // errorKeys.map(item => notifyError(item[0]))
      // });
      // console.log(companyInfoRes.data.contact.fax_number[0]);
      // console.log(companyInfoRes.data.user.email[0]);
      // notifyError(companyInfoRes.data.contact.fax_number[0]);
      // notifyError(companyInfoRes.data.user.email[0]);
    }
  };

  let addCompanySubmit = (data) => {
    registerPhaseApi(data);
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  useEffect(() => {
    !isSubmitted &&
      Cookies.set("companyValues", JSON.stringify(mehods.getValues()), {
        expires: 1,
      });
  });

  return (
    <>
      <AddCompanyStages
        changeActiveState={changeActiveState}
        activeStage={activeStage}
      />
      <Container>
        <FormProvider {...mehods}>
          <form
            onSubmit={mehods.handleSubmit(addCompanySubmit)}
            className={styles["add-company__stage-cont"]}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <AddCompanyCompanyInfo
              activeClass={`${activeStage === 1 ? " " : ACTIVE_CLASS_NAME}`}
              changeActiveState={(activeStage) => {
                changeActiveState(activeStage);
              }}
            />
            <AddCompanyContactInfo
              activeClass={`${activeStage === 2 ? " " : ACTIVE_CLASS_NAME}`}
              changeActiveState={(activeStage) => {
                changeActiveState(activeStage);
              }}
            />
            <AddCompanyAddressInfo
              activeClass={`${activeStage === 3 ? " " : ACTIVE_CLASS_NAME}`}
              changeActiveState={(activeStage) => {
                changeActiveState(activeStage);
              }}
            />
            <AddCompanyLoginInfo
              activeClass={`${activeStage === 4 ? " " : ACTIVE_CLASS_NAME}`}
              changeActiveState={(activeStage) => {
                changeActiveState(activeStage);
              }}
            />
          </form>
        </FormProvider>
      </Container>
    </>
  );
}
export default AddCompanyForm;
