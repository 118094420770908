import React from "react";
import styles from "../../src/components/Home/AboutUs/AboutUs.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as AboutLogo } from "assets/icons/aboutus.svg";
// import src from 'assets/imgs/aboutUsBG.png'
// import src from 'assets/imgs/company1.jpg'
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AboutUsViewPage() {
    const { t } = useTranslation();
    return (
      <section id={styles["about-us_page"]}>
        <Container>
          <Row>
            <Col md="6" xs="12">
              <div className={`${styles["about-us__img"]} d-flex h-100`}>
                <AboutLogo className={`m-auto`} />
              </div>
            </Col>
            <Col md="6" xs="12">
              <div className={styles["about-us__conetnt"]}>
                <h1 className={styles["about-us__conetnt-title"]}>
                  {t("about_us")}
                </h1>
                <p className={styles["about-us__conetnt-desc"]}>
                  {t("about_desc")}
                </p>
                <h1 className={styles["about-us__conetnt-title"]}>
                  {t("about_us_titleOne")}
                </h1>
                <p className={styles["about-us__conetnt-desc"]}>
                  {t("about_us_descOne")}
                </p>
                <h1 className={styles["about-us__conetnt-title"]}>
                  {t("about_us_titleTwo")}
                </h1>
                <p className={styles["about-us__conetnt-desc"]}>
                  {t("about_us_descTwo")}
                </p>
                {/* <div className="d-flex">
                  <NavLink
                    to="/about-us"
                    className={styles["about-us__read-more"]}
                  >
                    {t("about_us")}
                  </NavLink>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
}
