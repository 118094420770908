import React, { useEffect, useState } from 'react'
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function ChooseLocationFromGoogleMap({google}) {
  const style = {
    width: '100%',
    height: '100%'
  }
  const {setValue} =useFormContext()
  const [location ,setLocation] = useState({lat:30.976535,lng:31.164505})
  const {t} =useTranslation()
  let onMapClicked =(props, marker, e)=>{
    setLocation({lat:e.latLng.lat(),lng:e.latLng.lng()})
    setValue('name', 'value', { shouldValidate: true })
    setValue('latitude', e.latLng.lat(), { shouldValidate: true })
    setValue('longitude', e.latLng.lng(), { shouldValidate: true })
  }
  
  let moveMarker=(props, marker, e)=> {
    setLocation({lat:e.latLng.lat(),lng:e.latLng.lng()})
}
function showPosition(position) {
  setLocation({lat:position.coords.latitude,lng:position.coords.longitude})
  setValue('latitude', position.coords.latitude, { shouldValidate: true })
  setValue('longitude', position.coords.longitude, { shouldValidate: true })

}
const notifyError = () => toast.error(t('please_enable_location_permission'));

useEffect(()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else { 
      notifyError()
    }
},[])

  return (
    <Map
            google={google}
            style={style}
            initialCenter={{
                lat: location.lat,
                lng: location.lng
            }}
            zoom={7}
            onClick={onMapClicked}
            >
        <Marker
          title="Location"
          id={1}
          position={{
            lat: location.lat,
            lng: location.lng
          }}
          draggable={true}
          />
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDuoSKzIgwIxDmxk5JS_d8LtMukWqPHzRs'),
})(ChooseLocationFromGoogleMap)