import React from 'react'
import styles from './DescHeader.module.css'
import { Container } from 'react-bootstrap'
// import { useTranslation } from 'react-i18next'
function DescHeader({title}) {
  // const {t} =useTranslation()
  return (
    <section id={styles['cat-header']}>
        <div className={styles['comming-soon__animation']}></div>
        <div className={styles['comming-soon__animation']}></div>
        <div className={styles['comming-soon__animation']}></div>
        <div className={styles['comming-soon__animation']}></div>
        <div className={styles['comming-soon__animation']}></div>
      <Container>
        <h1 className={styles['cat-header__heading']}>{title}</h1>
      </Container>
    </section>
  )
}

export default DescHeader