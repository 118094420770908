import React from 'react'
import styles from './SideNavBar.module.css'
import {ReactComponent as LogOutSideBar} from 'assets/icons/logOutSideBar.svg'
import ProfileImage from 'assets/imgs/profile.png'
import { Form, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ChangeLangueList from '../ChangeLangueList/ChangeLangueList'
import { ReactComponent as SearchLogo } from 'assets/icons/search.svg'

function SideNavBar() {
    let toggleSideNavBar = (type)=>{
        let sideNavBar= document.querySelector('.js-side-navbar')
        type === 'open'? sideNavBar.classList.add(styles['side-navbar--open']):sideNavBar.classList.remove(styles['side-navbar--open'])
    }
    const {t} =useTranslation()
    return (
        <Navbar className={`${styles["side-navbar"]} js-side-navbar`} >
        <div className={styles["side-navbar__content"]}>
            <ul className={styles["side-navbar__nav-list"]}>
                {/* <li className={`${styles["side-navbar__nav-item"]} ${styles['active']}`}>
                    <NavLink to='/' className={styles["side-navbar__nav-link"]}>{t('home')}</NavLink>
                </li> */}
                
                <li className={`${styles["side-navbar__nav-item"]} ${styles['active']}`}>
                    <NavLink to='/add-company' className={styles["side-navbar__nav-link"]}>{t('add_company')}</NavLink>
                </li>
                
                {/* <li className={styles["side-navbar__nav-item"]}>
                    <NavLink to='/Login' className={styles["side-navbar__nav-link"]}>{t('categories')}</NavLink>
                </li> */}
                {/* <li>
                    {
                    window.location.pathname!=='/'&&
                        <Form className={styles['navbar__search-form']}>
                            <Form.Select aria-label="Default select example" className={styles['navbar__search-select']}>
                                <option>{t('all')}</option>
                                <option value="1">{t('one')}</option>
                                <option value="2">{t('two')}</option>
                                <option value="3">{t('three')}</option>
                            </Form.Select>
                            <input type='text'placeholder={t('search_words')} className={styles['navbar__search-input']}/>
                            <button type='submit' className={styles['navbar__search-submit']}>
                                <SearchLogo className={styles['navbar__search-icon']}/>
                            </button>
                        </Form>
                    }
                </li> */}
                <div className={styles['change-lang-cont']}>
                    <ChangeLangueList/>
                </div>
                {/* <button className={styles['navbar__account']}>{t('my_account')}</button> */}

            </ul>
        </div>
        <div className={styles["side-navbar__overlayer"]} onClick={()=>{toggleSideNavBar('close')}}></div>
        </Navbar>
    )
}

export default SideNavBar