import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './features/i18nInit'
import Loading from 'components/Global/Loading/Loading';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Loading/>}>
    <App />
  </Suspense>
);
