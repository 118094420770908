import React from 'react'
import styles from './ErrorComp.module.css'

import {ReactComponent as Logo} from 'assets/icons/logo-en-comming-soon.svg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
function CommingSoonComp() {
    const {t}= useTranslation()
  return (
    <section className={styles['Error']}>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <div className={styles['Error__animation']}></div>
        <Container className='h-100'>
            <div className={styles['Error__content-cont']}>
                <Logo className={styles['Error__logo']}/>
                <div className={styles['Error__content']}>
                    {/* <h2 className={styles['Error__sub-title']}>{t('error')}</h2> */}
                    <h1 className={styles['Error__title']}>{t('error')}</h1>
                    <p className={styles['Error__desc']}>{t('page_isnt_available')}</p>
                    <p className={styles['Error__desc']}>{t('try_search_another')}</p>
                    <NavLink to='/' className={styles['Error__add-button']}>{t('go_back')}</NavLink>
                </div>
            </div>
        </Container>
    </section>
  )
}

export default CommingSoonComp