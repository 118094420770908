import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const axiosConfig = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("i18next") || 'en',
  },
});

export default axiosConfig;
