import axiosConfig from 'features/axiosConfig'

async function getCitites(GovernmentId) {
    let response = {data:[],status:false}
    try{
        let res = await axiosConfig.get(`/location/${GovernmentId}/city/`)
        response.status=true;
        response.data = res.data.result;
        return response
    }catch(e){
        response.data = e.message
        return response
    }
}

export default getCitites