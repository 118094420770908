import React, { useEffect, useRef, useState } from "react";
import styles from "./AddCompanyCompanyInfo.module.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddImageLogo } from "assets/icons/addImageIcon.svg";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import getCategories from "api/getCategories";
import getSubCategoriesFormAPI from "api/getSubCategories";

function AddCompanyCompanyInfo({ activeClass, changeActiveState }) {
  const { t } = useTranslation();
  const {
    register,
    unregister,
    formState: { errors },
    trigger,
    getValues,
  } = useFormContext({
    revalida: "onTouched",
  });
  const notifyError = () => toast.error(t("please_complete_all_fields"));
  // const [allCategories, setAllCategories] =useState(null)
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const AddFile = useRef(null);
  const AddFileInput = useRef(null);
  const [imageUrl, setImage] = useState(null);
  const AddFileCover = useRef(null);
  const AddFileInputCover = useRef(null);
  const [coverUrl, setCover] = useState(null);
  const notifyLoading = () => toast.loading(t("Please wait..."));
  let previewUploadedImage = (e, callBack) => {
    console.log(e.target.files)
    let file = e.target.files[0];
    console.log(e.target.files)
    console.log(callBack)
    if (!file) {
      console.log(e)
      return;
    }
    console.log(e.target.files)
    let previewLink = URL.createObjectURL(file);
    callBack(previewLink);
  };
  let goToNextStage = () => {
    changeActiveState(2);
    window.scrollTo(0, 0);
  };

  let checkAllFieldsFirstThenNavigate = async () => {
    const result = await trigger([
      "logo",
      "cover",
      "company_en",
      "company_ar",
      "categories",
      "sub_categories",
      "description_en",
      "description_ar",
      "trading_reg",
      "tax_iD",
    ]);
    console.log(result);
    if (!result) {
      notifyError();
      return;
    }
    goToNextStage();
  };

  let coverInput = register("cover", {
    required: t("please_choose_cover"),
    onChange: (e) => {
      previewUploadedImage(e, setCover);
      console.log(coverUrl);
    },
  });
  let logoInput = register("logo", {
    required: t("please_choose_logo"),
    onChange: (e) => {
      previewUploadedImage(e, setImage);
    },
  });
  let getCategoriesFromApi = async () => {
    toast.dismiss();
    notifyLoading();
    // setSubCategories(null)
    let categoriesData = await getCategories();
    if (categoriesData.status) {
      setCategories(categoriesData.data);
    }
  };
  let getSubCategories = async (id) => {
    console.log(id);
    let categoriesData = await getSubCategoriesFormAPI(id);
    if (categoriesData.status) {
      setSubCategories(categoriesData.data);
    }
  };
  useEffect(() => {
    unregister("categories");
    unregister("sub_categories");
    getCategoriesFromApi();
  }, []);

  return (
    <section
      className={`${styles["add-company__Company"]} ${styles[activeClass]}`}
    >
      <Container>
        <h1 className={styles["add-company__Company-title"]}>
          {t("company_information")}
        </h1>
        <div className={styles["add-company__Company-cont"]}>
          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-3`}
            >
              {t("logo")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div
              className={`${styles["add-company__field-upload-cont"]} col-9 `}
            >
              <input
                type="file"
                className="d-none"
                {...logoInput}
                tabIndex="-1"
                ref={(e) => {
                  logoInput.ref(e);
                  AddFileInput.current = e;
                }}
              />
              {imageUrl == null ? (
                <>
                  <div
                    className={styles["add-company__field-file"]}
                    ref={AddFile}
                    onClick={() => {
                      AddFileInput.current.click();
                    }}
                  >
                    <AddImageLogo />
                  </div>
                  {errors.logo && (
                    <span className={styles["add-company__error-message"]}>
                      {errors.logo?.message}
                    </span>
                  )}
                </>
              ) : (
                <div>
                  <img
                    src={imageUrl}
                    alt="image"
                    className={styles["add-company__field-file"]}
                    onClick={() => {
                      AddFileInput.current.click();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-3`}
            >
              {t("cover")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div
              className={`${styles["add-company__field-upload-cont"]} col-9 `}
            >
              <input
                type="file"
                className="d-none"
                {...coverInput}
                tabIndex="-1"
                ref={(e) => {
                  coverInput.ref(e);
                  AddFileInputCover.current = e;
                }}
              />
              {coverUrl == null ? (
                <>
                  <div
                    className={styles["add-company__field-file"]}
                    ref={AddFileCover}
                    onClick={() => {
                      AddFileInputCover.current.click();
                    }}
                  >
                    <AddImageLogo />
                  </div>
                  {errors.cover && (
                    <span className={styles["add-company__error-message"]}>
                      {errors.cover?.message}
                    </span>
                  )}
                </>
              ) : (
                <div>
                  <img
                    src={coverUrl}
                    alt="image"
                    className={styles["add-company__field-file"]}
                    onClick={() => {
                      AddFileInputCover.current.click();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("company_en")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <input
                type="text"
                className={`${styles["add-company__field-input"]}`}
                {...register("company_en", {
                  required: t("please_add_company_en"),
                })}
              />
              {errors.company_en && (
                <span className={styles["add-company__error-message"]}>
                  {errors.company_en?.message}
                </span>
              )}
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("company_ar")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <input
                type="text"
                className={`${styles["add-company__field-input"]}`}
                {...register("company_ar", {
                  required: t("please_add_company_ar"),
                })}
              />
              {errors.company_ar && (
                <span className={styles["add-company__error-message"]}>
                  {errors.company_ar?.message}
                </span>
              )}
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("categories")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <select
                className={`${styles["add-company__field-input"]}`}
                defaultValue={getValues().categories && getValues().categories}
                {...register("categories", {
                  required: t("please_select_categories"),
                  onChange: () => getSubCategories(getValues().categories),
                })}
              >
                <option value="" defaultValue="">
                  {t("please_select_categories")}
                </option>

                {categories &&
                  categories.map((cat, index) => (
                    <option value={cat.id} key={cat.id}>
                      {cat.name}
                    </option>
                  ))}
              </select>
              {errors.categories && (
                <span className={styles["add-company__error-message"]}>
                  {errors.categories?.message}
                </span>
              )}
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("sub_categories")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <select
                className={`${styles["add-company__field-input"]}`}
                value={getValues().sub_categories && getValues().sub_categories}
                {...register("sub_categories", {
                  required: t("please_select_sub_categories"),
                })}
              >
                <option value="" disabled>
                  {t("please_select_sub_categories")}
                </option>
                {subCategories &&
                  subCategories.map((subCat, index) => (
                    <option value={subCat.id} key={subCat.id}>
                      {subCat.name}
                    </option>
                  ))}
              </select>
              {errors.sub_categories && (
                <span className={styles["add-company__error-message"]}>
                  {errors.sub_categories?.message}
                </span>
              )}
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("description_en")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <textarea
                rows="5"
                className={`${styles["add-company__field-input"]}  ${styles["add-company__field-input--textarea"]}`}
                {...register("description_en", {
                  required: t("please_add_description_en"),
                })}
              ></textarea>
              {errors.description_en && (
                <span className={styles["add-company__error-message"]}>
                  {errors.description_en?.message}
                </span>
              )}
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("description_ar")}
              <span className={styles["add-company__required"]}>*</span>
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <textarea
                rows="5"
                className={`${styles["add-company__field-input"]} ${styles["add-company__field-input--textarea"]}`}
                {...register("description_ar", {
                  required: t("please_add_description_ar"),
                })}
              ></textarea>
              {errors.description_ar && (
                <span className={styles["add-company__error-message"]}>
                  {errors.description_ar?.message}
                </span>
              )}
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("trading_reg")}
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <input
                type="text"
                className={`${styles["add-company__field-input"]}`}
                {...register("trading_reg")}
              />
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <label
              htmlFor={styles["add-company__field"]}
              className={`${styles["add-company__field-label"]} col-md-3 col-12`}
            >
              {t("tax_iD")}
            </label>
            <div className="col-md-9 col-12 d-flex flex-column">
              <input
                maxLength="15"
                type="text"
                className={`${styles["add-company__field-input"]}`}
                {...register("tax_iD")}
              />
            </div>
          </div>

          <div className={`${styles["add-company__Company-field"]} row`}>
            <button
              type="button"
              className={styles["add-company__next-stage"]}
              onClick={checkAllFieldsFirstThenNavigate}
            >
              <span>{t("next")}</span>
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default AddCompanyCompanyInfo;
