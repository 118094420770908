import React from 'react'
import styles from './AddCompanyLoginInfo.module.css'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify';
// import ConfirmCompanyModal from '../ConfirmCompanyModal/ConfirmCompanyModal'
import { passwordPattern } from 'utils/features'

function AddCompanyLoginInfo({ activeClass ,changeActiveState }) {
    const {t} =useTranslation()
    const notifyError = () => toast.error(t('please_complete_all_fields'));

    const { register ,formState:{errors} ,trigger ,getValues } =useFormContext()
    let goToPrevStage=()=>{
        changeActiveState(3)
        window.scrollTo(0,0)
    }
    
    let checkAllFieldsFirstThenNavigate =async ()=>{
        const result = await trigger(["account_name","account_email","password","confirm_password"]);
        if(!result){
            notifyError()
            return;
        }
    }

  return (
    <section className={`${styles['add-company__login']} ${styles[activeClass]}`}>
        <Container>
            <h1 className={styles['add-company__login-title']}>{t('login_information')}</h1>
            <div className={styles['add-company__login-cont']}>
                <div className={`${styles['add-company__login-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                        {t('name')}
                        <span className={styles['add-company__required']}>*</span>
                    </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            <input type="text"  className={`${styles['add-company__field-input']}`} 
                            {...register("account_name.",{required:t('please_add_name')})}/>
                            {errors.name && <span className={styles['add-company__error-message']}>{errors.name?.message}</span>}
                        </div>
                </div>

                <div className={`${styles['add-company__login-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                        {t('email')}
                        <span className={styles['add-company__required']}>*</span>
                    </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            <input type="text" className={`${styles['add-company__field-input']}`} 
                            {...register("account_email",{required:t('please_add_email')})}/>
                            {errors.email && <span className={styles['add-company__error-message']}>{errors.email?.message}</span>}
                        </div>
                </div>

                <div className={`${styles['add-company__login-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                        {t('password')}
                        <span className={styles['add-company__required']}>*</span>
                    </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            <input type="password" className={`${styles['add-company__field-input']}`} 
                            {...register("password",{required:t('please_add_password'),
                            pattern:{
                                value:passwordPattern,
                                message:t('password_pattern_message')
                            }})}/>
                            {errors.password && <span className={styles['add-company__error-message']}>{errors.password?.message}</span>}
                        </div>
                </div>

                <div className={`${styles['add-company__login-field']} row`}>
                    <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                        {t('confirm_password')}
                        <span className={styles['add-company__required']}>*</span>
                    </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            <input type="password" className={`${styles['add-company__field-input']}`} 
                            {...register("confirm_password",
                            {required:t('please_add_confirm_password'),validate:{
                                checkPasswordEqualToConfirmPassword :(value)=>{
                                    let pass = getValues().password
                                    return pass ===value || t('password_isnt_equal')
                                }
                            }})}/>
                            {errors.confirm_password && <span className={styles['add-company__error-message']}>{errors.confirm_password?.message}</span>}
                        </div>
                </div>

                <div className={`${styles['add-company__contact-field']} row`}>
                    <button type='button' className={`${styles['add-company__move-stage']} ${styles['add-company__move-stage--prev']}`}  onClick={goToPrevStage}><span>{t('prev')}</span></button>
                    {/* {
                        (getValues().first_name && getValues().last_name && getValues().password && getValues().confirm_password ) &&
                        <ConfirmCompanyModal comapnyInfo={getValues()}/>

                    } */}
                    <button type='submit' className={`${styles['add-company__move-stage']} ${styles['add-company__move-stage--submit']}`}onClick={checkAllFieldsFirstThenNavigate}><span>{t('submit')}</span></button>
                </div>

            </div>
        </Container>
    </section>
  )
}

export default AddCompanyLoginInfo