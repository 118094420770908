import React from 'react'
import { Route, Routes ,useLocation } from 'react-router-dom';
// import {AnimatePresence} from 'framer-motion/dist/framer-motion'
import AddCompany from 'views/AddCompany';
import CategoriesView from 'views/Categories';
import PreviewCompany from 'views/PreviewCompany';
import CommingSoon from 'views/CommingSoon';
import Error from 'views/Error';
import Home from 'views/Home';
import AboutUsView from 'views/AboutUs'
import ContactUsView from 'views/ContactUs'
import { AnimatePresence } from 'framer-motion';
function AnimatedRoutes() {
    const location =useLocation()
  return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            {/* <Route exact path='/' element={<Home />}></Route> */}
            <Route path='/categories' element={<CategoriesView />}></Route>
            <Route path='/about-us' element={<AboutUsView />}></Route>
            <Route path='/contact-us' element={<ContactUsView />}></Route>
            <Route path='/add-company' element={<AddCompany />}></Route>
            <Route path='/preview-company' element={<PreviewCompany />}></Route>
            <Route path='/comming-soon' element={<CommingSoon />}></Route>
            <Route path='/' element={<CommingSoon />}></Route>
            <Route path='*' element={<Error />}></Route>
          </Routes>
    </AnimatePresence>

  )
}

export default AnimatedRoutes