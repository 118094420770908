import React from 'react'
import styles from './CompanyDetails.module.css'
import { useTranslation } from 'react-i18next'

import PreviewComment from '../PreviewComment/PreviewComment'
import PreviewCompanyInfo from '../PreviewCompanyInfo/PreviewCompanyInfo'
import CompanyDescription from '../CompanyDescription/CompanyDescription'
import GoogleMapPreview from '../GoogleMapPreview/GoogleMapPreview'

import {ReactComponent as PhoneIcon} from 'assets/icons/phone.svg'
import {ReactComponent as MailIcon} from 'assets/icons/mail.svg'
import {ReactComponent as WebsiteIcon} from 'assets/icons/website.svg'
import {ReactComponent as YoutubeIcon} from 'assets/icons/youtube.svg'
import {ReactComponent as FacebookIcon} from 'assets/icons/facebook.svg'
import {ReactComponent as TwitterIcon} from 'assets/icons/twitter.svg'
function CompanyDetails() {
    const {t} =useTranslation()
  return (
    <>  
        <section className={styles['preview-company']}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className={styles['preview-company__company-info']}>
                            <PreviewCompanyInfo/>
                            <CompanyDescription/>
                            <PreviewComment/>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className={styles['preview-company__location']}>
                            <div className={styles['preview-company__location-cont']}><GoogleMapPreview/></div>
                            <div className={styles['preview-company__location-name']}>
                                <p>
                                    <span className={styles['preview-company__location-cat']}>{t('location')}</span>
                                    <span className={styles['preview-company__location-desc']}>{t('location_address')}</span>
                                </p>
                            </div>
                        </div>
                            <div className={styles['preview-company__details']}>
                                <p>
                                    <span className={styles['preview-company__details-name']}>0100200334</span>
                                    <span><PhoneIcon/></span>
                                </p>
                            </div>

                            <div className={styles['preview-company__details']}>
                                <p>
                                    <span>companyemail@mail.com</span>
                                    <span><MailIcon/></span>
                                </p>
                            </div>

                            <div className={styles['preview-company__details']}>
                                <p>
                                    <span>www.companyemailcom</span>
                                    <span><WebsiteIcon/></span>
                                </p>
                            </div>

                            <div className={`${styles['preview-company__details']} ${styles['preview-company__details--display']}`}>
                                <div>
                                    <button><FacebookIcon/></button>
                                    <button><TwitterIcon/></button>
                                    <button><YoutubeIcon/></button>
                                </div>
                            </div>

                            <div className={styles['preview-company__details']}>
                                <p className={styles['preview-company__details-para']}>
                                    <span>Trading Registration Authority No. </span>
                                    <span>: 62466</span>
                                </p>
                                <p className={styles['preview-company__details-para']}>
                                    <span>Tax ID</span>
                                    <span>: 56662466</span>
                                </p>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CompanyDetails