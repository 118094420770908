import React, { useEffect,  useState } from 'react'
import styles from './AddCompanyAddressInfo.module.css'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify';
import ChooseLocationFromGoogleMap from '../ChooseLocationFromGoogleMap/ChooseLocationFromGoogleMap'
import getCountries from 'api/getCountries'
import getGoverments from 'api/getGoverments'
import getCitites from 'api/getCitites'

function AddCompanyAddressInfo({ activeClass ,changeActiveState }) {
    const {t} =useTranslation()
    const [countries , setCountries] =useState(null)
    const [goverments , setGoverments] =useState(null)
    const [cities , setcities] =useState(null)
    const { register,unregister ,formState:{errors} ,trigger  ,getValues } =useFormContext()
    const notifyError = () => toast.error(t('please_complete_all_fields'));
    const notifyLoading = () => toast.loading(t("Please wait..."))
    let goToNextStage=()=>{
        changeActiveState(4)
        window.scrollTo(0,0)
    }
    let goToPrevStage=()=>{
        changeActiveState(2)
        window.scrollTo(0,0)
    }
    let checkAllFieldsFirstThenNavigate =async ()=>{
        const result = await trigger(["country","city","goverment","latitude","address_ar","address_en","longitude"]);
        if(!result){
            notifyError()
            return;
        }
        goToNextStage()
        
    }
    let getContriesFromApi = async ()=>{
        toast.dismiss();
        setGoverments(null)
        setcities(null)
        let countriesData = await getCountries()
        if(countriesData.status){
            setCountries(countriesData.data)
        }
    }
    let getGovermentsFromApi = async (countryId)=>{
        toast.dismiss();
        notifyLoading()
        setGoverments(null)
        setcities(null)
        let govermentsData = await getGoverments(countryId)
        if(govermentsData.status){
            setGoverments(govermentsData.data)
            toast.dismiss();
        }else{
            toast.dismiss();
        }
    }
    let getCitiesFromApi = async (governmentId)=>{
        toast.dismiss();
        notifyLoading()
        setcities(null)
        let citiesData = await getCitites(governmentId)
        if(citiesData.status){
            setcities(citiesData.data)
            toast.dismiss();
        }else{
            toast.dismiss();
        }
    }
        useEffect(()=>{
            unregister('country')
            unregister('city')
            unregister('goverment')
            getContriesFromApi()
        },[])

  return (
    <section className={`${styles['add-company__address']} ${styles[activeClass]}`}>
        <Container>
            <h1 className={styles['add-company__address-title']}>{t('address_information')}</h1>
                <div className={styles['add-company__address-cont']}>
                    
                    <div className={`${styles['add-company__address-field']} row`}>
                        <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                            {t('countries')}
                            <span className={styles['add-company__required']}>*</span>
                        </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            <select className={`${styles['add-company__field-input']}`} value={getValues().country && getValues().country}
                            {...register("country",{required:t('please_choose_country')
                            ,onChange: () =>  getGovermentsFromApi(getValues().country)})}>
                                <option value=''disabled selected>{t('please_choose_country')}</option>
                                {
                                    countries && countries.map(country=>(
                                        <option value={country.id} key={country.id}>{country.name}</option>
                                    ))
                                }
                            </select>
                            {errors.country && <span className={styles['add-company__error-message']}>{errors.country?.message}</span>}
                        </div>
                    </div>

                    <div className={`${styles['add-company__address-field']} row`}>
                        <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                            {t('government')}
                            <span className={styles['add-company__required']}>*</span>
                        </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            <select className={`${styles['add-company__field-input']}`} value={getValues().goverment && getValues().goverment}
                            {...register("goverment",{required:t('please_choose_goverment')
                                ,onChange: () => getCitiesFromApi(getValues().goverment)})}>
                                <option value=''disabled selected>{t('please_choose_goverment')}</option>
                                {
                                    goverments && goverments.map((goverment,index)=>(
                                        <option value={goverment.id} key={goverment.id}>{goverment.name}</option>
                                    ))
                                }
                            </select>
                            {errors.goverment && <span className={styles['add-company__error-message']}>{errors.goverment?.message}</span>}
                        </div>
                    </div>

                    <div className={`${styles['add-company__address-field']} row`}>
                        <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                            {t('citites')}
                            <span className={styles['add-company__required']}>*</span>
                        </label>
                        <div className='col-sm-9 col-8 d-flex flex-column'>
                            
                            <select className={`${styles['add-company__field-input']}`} value={getValues().city && getValues().city}
                            {...register("city",{required:t('please_choose_city')})}>
                                <option value=''disabled selected>{t('please_choose_city')}</option>
                                {
                                    cities && cities.map((city,index)=>(
                                        <option value={city.id} key={city.id}>{city.name}</option>
                                    ))
                                }
                            </select>
                            {errors.city && <span className={styles['add-company__error-message']}>{errors.city?.message}</span>}
                        </div>
                    </div>
                    
                    <div className={`${styles['add-company__address-field']} row`}>
                        <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>
                            {t('address_en')}
                            <span className={styles['add-company__required']}>*</span>
                        </label>
                        <div className='col-md-9 col-12 d-flex flex-column'>
                            <textarea rows="5"className={`${styles['add-company__field-input']}  ${styles['add-company__field-input--textarea']}`}
                            {...register("address_en",{required:t('please_add_address_en')})}></textarea>
                            {errors.address_en && <span className={styles['add-company__error-message']}>{errors.address_en?.message}</span>}
                        </div>
                    </div>

                    <div className={`${styles['add-company__address-field']} row`}>
                        <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-md-3 col-12`}>
                            {t('address_ar')}
                            <span className={styles['add-company__required']}>*</span>
                        </label>
                        <div className='col-md-9 col-12 d-flex flex-column'>
                            <textarea rows="5"className={`${styles['add-company__field-input']}  ${styles['add-company__field-input--textarea']}`}
                            {...register("address_ar",{required:t('please_add_address_ar')})}></textarea>
                            {errors.address_ar && <span className={styles['add-company__error-message']}>{errors.address_ar?.message}</span>}
                        </div>
                    </div>
                

                    <div className={`${styles['add-company__address-field']} row`}>
                        <label htmlFor={styles['add-company__field']} className={`${styles['add-company__field-label']} col-sm-3 col-4`}>
                            {t('location')}
                            <span className={styles['add-company__required']}>*</span>
                        </label>
                        <div className={`${styles['add-company__address-field--height']} col-sm-9 col-8 d-flex flex-column`}>
                            
                            <input type="hidden" className={`${styles['add-company__field-input']}`} 
                            {...register("latitude",{required:t('please_choose_location')})}/>
                            <input type="hidden" className={`${styles['add-company__field-input']}`} 
                            {...register("longitude",{required:t('please_choose_location')})}/>
                            <ChooseLocationFromGoogleMap />
                            {errors.location && <span className={styles['add-company__error-message']}>{errors.location?.message}</span>}
                        </div>
                    </div>

                    <div className={`${styles['add-company__address-field']} row`}>
                        <button type='button' className={`${styles['add-company__move-stage']} 
                        ${styles['add-company__move-stage--prev']}`} onClick={goToPrevStage}><span>{t('prev')}</span></button>
                        <button type='button' className={`${styles['add-company__move-stage']} 
                        ${styles['add-company__move-stage--next']}`} onClick={checkAllFieldsFirstThenNavigate}><span>{t('next')}</span></button>
                    </div>
                </div>
        </Container>
    </section>
  )
}

export default AddCompanyAddressInfo