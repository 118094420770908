import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from './AddCompanyStages.module.css'
function AddCompanyStages({changeActiveState , activeStage}) {
    const {t} =useTranslation()
    
    return (
    <section className={styles['add-company-stages']}>
        <Container>
            <div className={styles['add-company-stages__cont']}>
                <div className={`${styles['add-company__stage']} ${activeStage >=1 ?styles['add-company__stage--completed']:''}`}>
                    <span>{t('company_information')}</span></div>
                
                <div className={`${styles['add-company__stage']} ${activeStage >=2 ?styles['add-company__stage--completed']:''}`}>
                    <span>{t('contacts_information')}</span></div>
                
                <div className={`${styles['add-company__stage']} ${activeStage >=3 ?styles['add-company__stage--completed']:''}`}>
                    <span>{t('address_information')}</span></div>

                <div className={`${styles['add-company__stage']} ${activeStage >=4 ?styles['add-company__stage--completed']:''}`}>
                    <span>{t('login_information')}</span></div>
            </div>
        </Container>
    </section>
    )
}

export default AddCompanyStages