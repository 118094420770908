import React, { useState } from 'react'
import styles from './Categories.module.css'
import { Container , Row ,Col } from 'react-bootstrap'
import CategoriesFilter from 'components/Global/CategoriesFilter/CategoriesFilter'
import Category from 'components/Global/Category/Category'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
function Categories() {
  const {t} =useTranslation()
  const [activeCategory ,setActiveCategory] = useState(1)
  let changeActiveCategory = (activeCat)=>{
    setActiveCategory(activeCat)
  }
  
  return (
    <section id='categories' className='mb-5'>
        <Container>
            <CategoriesFilter activeCategory={activeCategory} changeActiveCategory={(activeCat)=>{changeActiveCategory(activeCat)}}/>
            <Row>
                <Col lg="6" md="12"><Category/></Col>
                <Col lg="6" md="12"><Category/></Col>
                <Col lg="6" md="12"><Category/></Col>
                <Col lg="6" md="12"><Category/></Col>
                <Col lg="6" md="12"><Category/></Col>
                <Col lg="6" md="12"><Category/></Col>
            </Row>
            <div className='d-flex'>
                <NavLink to='/category' className={styles['categories__show-all']}>{t('view_all')}</NavLink>
            </div>
        </Container>
    </section>
  )
}

export default Categories