import React from "react";
import styles from "./CommingSoonComp.module.css";

import { ReactComponent as Logo } from "assets/icons/logo-en-comming-soon.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import ChangeLangueList from "components/Global/ChangeLangueList/ChangeLangueList";
function CommingSoonComp() {
  const { t } = useTranslation();
  return (
    <section className={styles["comming-soon"]}>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <div className={styles["comming-soon__animation"]}></div>
      <Container className="h-100">
        <div>
          <ChangeLangueList className={styles["comming-soon__lang"]} />
        </div>
        <div className={styles["comming-soon__content-cont"]}>
          <Logo className={styles["comming-soon__logo"]} />
          <div className={styles["comming-soon__content"]}>
            <h2 className={styles["comming-soon__sub-title"]}>
              {t("hey_guys")}
            </h2>
            <h1 className={styles["comming-soon__title"]}>
              {t("we_are_coming_soon")}
            </h1>
            <p className={styles["comming-soon__desc"]}>
              {t("comming_soon_desc")}
            </p>
            <NavLink
              to="/add-company"
              className={styles["comming-soon__add-button"]}
            >
              {t("add_company")}
            </NavLink>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default CommingSoonComp;
