import axiosConfig from "features/axiosConfig";

async function submitCompany(companyInfo, token) {
  let response = { data: [], status: false };
  try {
    let res = await axiosConfig.post(`/company/`, companyInfo, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    response.status = true;
    response.data = res.data.result;
    return response;
  } catch (e) {
    console.log(e.response.data);
    response.data = e.response.data.error
      // ? e.response.data.error.user.email[0]
      // : e.message;
    return response;
  }
}

export default submitCompany;
