import DescHeader from "components/Global/DescHeader/DescHeader";
import Footer from "components/Global/Footer/Footer";
import MainNavBar from "components/Global/MainNavBar/MainNavBar";
import SideNavBar from "components/Global/SideNavBar/SideNavBar";
import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import AboutUsViewPage from "./AboutUsViewPage";

function AboutUsView() {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        init={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("about_us")}</title>
        </Helmet>
        <MainNavBar />
        <SideNavBar />
        <DescHeader title={t("about_us")} />
        <AboutUsViewPage />
        <Footer />
      </motion.div>
    </>
  );
}

export default AboutUsView;
