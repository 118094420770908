import React, { useEffect, useState } from 'react'
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styles from './GoogleMapPreview.module.css'
function GoogleMapPreview({google}) {
  const style = {
    width: '100%',
    height: '380px',
    borderRadius:'10px',
    position:'relative',
  }
  const {t} = useTranslation();
  const [location ,setLocation] = useState({lat:30.976535,lng:31.164505})
function showPosition(position) {
  setLocation({lat:position.coords.latitude,lng:position.coords.longitude})
}
const notifyError = () => toast.error(t('please_enable_location_permission'));

useEffect(()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else { 
      notifyError()
    }
},[])

  return (
    <Map
            google={google}
            style={style}
            initialCenter={{
                lat: location.lat,
                lng: location.lng
            }}
            zoom={7}
            className={styles['google-map']}
            >
        <Marker
          title="Location"
          id={1}
          position={{
            lat: location.lat,
            lng: location.lng
          }}
          draggable={true}
          />
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDuoSKzIgwIxDmxk5JS_d8LtMukWqPHzRs'),
})(GoogleMapPreview)