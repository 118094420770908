import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from 'reduxStore/store';
import AnimatedRoutes from 'AnimatedRoutes';
// import AnimatedRoutes from './AnimatedRoutes';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <AnimatedRoutes/>
          {/* <Routes >
            <Route exact path='/' element={<Home />}></Route>
            <Route path='/categories' element={<CategoriesView />}></Route>
            <Route path='/add-company' element={<AddCompany />}></Route>
            <Route path='/preview-company' element={<PreviewCompany />}></Route>
            <Route path='/comming-soon' element={<CommingSoon />}></Route>
            <Route path='*' element={<Error />}></Route>
          </Routes> */}
        </BrowserRouter>
        <ToastContainer/>
      </div>
     </Provider>
  );
}

export default App;
