import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './LoginWithSocialMedia.module.css'
import {ReactComponent as FacebookLogo} from 'assets/icons/facebook.svg'
import {ReactComponent as TwitterLogo} from 'assets/icons/twitter.svg'
import {ReactComponent as GogleLogo} from 'assets/icons/google.svg'
function LoginWithSocialMedia() {
  const {t} =useTranslation()
  return (
    <div className={styles['preview-company__login-with-social']}>
      <div className={styles['preview-company__login-with-social-cont']}>
        <p>{t('login_with_social')}</p>
      </div>
      <div className={styles['preview-company__login-with-social-icons-cont']}>
        <button className={styles['preview-company__login-with-social-icon']}>
          <FacebookLogo/>
        </button>
        <button className={styles['preview-company__login-with-social-icon']}>
          <TwitterLogo/>
        </button>
        <button className={styles['preview-company__login-with-social-icon']}>
          <GogleLogo/>
        </button>
      </div>
    </div>
  )
}

export default LoginWithSocialMedia