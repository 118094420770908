import React, { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguageAction } from "reduxStore/Global/GlobalActions";
import stylesNav from "../MainNavBar/MainNavBar.module.css";

function ChangeLangueList({ className }) {
  const languages = [
    {
      code: "en",
      name: "english",
      country_code: "gb",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
      dir: "rtl",
    },
  ];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguageCode = localStorage.getItem("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const Lang = useSelector((reducer) => reducer.GlobalReducer.state.lang);
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);
  let changeLanguage = (code) => {
    window.location.reload(true);
    i18next.changeLanguage(code);
    changeLanguageAction(dispatch, code);
    localStorage.setItem("i18next", code);
  };

  return (
    <>
      <button
        className={`${stylesNav["navbar__change-langs"]} ${className}`}
        onClick={(code) => {
          changeLanguage(localStorage.getItem("i18next") == "en" ? "ar" : "en");
        }}
      >
        {t("lang")}
      </button>
    </>
  );
}

export default ChangeLangueList;
