import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AddReview.module.css'
function AddReview() {
    const {t} =useTranslation()

  return (
    <form className={styles['add-review__form']}>
        <textarea placeholder={t('review_here')} className={styles['add-review__input']}></textarea>
        <button type='submit' className={styles['add-review__submit']}>{t('send')}</button>
    </form>
  )
}

export default AddReview