import React from 'react'
import { Nav, Navbar , Container ,NavDropdown} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import CustomDropdownMenu from '../CustomDropdownMenu/CustomDropdownMenu'
import Form from 'react-bootstrap/Form';
import styles from './MainNavBar.module.css'
import stylesSideNavBar from '../SideNavBar/SideNavBar.module.css'
import { ReactComponent as Logo } from 'assets/icons/logo-en.svg'
import { ReactComponent as SearchLogo } from 'assets/icons/search.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/menuIcon.svg'
import { useSelector } from 'react-redux';
import ChangeLangueList from '../ChangeLangueList/ChangeLangueList';
import { useTranslation } from 'react-i18next';
function MainNavBar() {
  const {t} =useTranslation()
    let selector = useSelector(reducer=>reducer.GlobalReducer)
    let toggleSideNavBar = (type)=>{
        let sideNavBar= document.querySelector('.js-side-navbar')
        type === 'open'? sideNavBar.classList.add(stylesSideNavBar['side-navbar--open']):sideNavBar.classList.remove(stylesSideNavBar['side-navbar--open'])
    }
    
  return (
    <Navbar expand="sm" className={styles['navbar']}>
      <Container fluid>
          <NavLink to='/' className={styles['navbar-brand']}><Logo/></NavLink>
          <button className={styles['main-navbar__toggle-button']} onClick={()=>toggleSideNavBar('open')}>
              <MenuIcon className={styles['main-navbar__toggle-icon']}/>
          </button>

        <Navbar.Collapse className={styles['navbar-collapse']} id="basic-navbar-nav" >

          <Nav className=" align-items-center w-100">
            <div className='d-flex align-items-center justify-content-center w-100'>
              {/* {
                window.location.pathname!=='/'&&
                <Form className={styles['navbar__search-form']}>
                  <Form.Select aria-label="Default select example" className={`${styles['navbar__search-select']}`}>
                      <option>{t('all')}</option>
                      <option value="1">{t('one')}</option>
                      <option value="2">{t('two')}</option>
                      <option value="3">{t('three')}</option>
                  </Form.Select>
                  <input type='text'placeholder={t('search_words')} className={styles['navbar__search-input']}/>
                  <button type='submit' className={styles['navbar__search-submit']}>
                      <SearchLogo className={styles['navbar__search-icon']}/>
                  </button>
              </Form>
              } */}
                  {/* <NavLink to='/add-company' className={`${styles['navbar__link']} nav-link`}>{t('add_company')}</NavLink> */}
                  {/* <NavLink to='/categories' className={`${styles['navbar__link']} nav-link`}>{t('categories')}</NavLink> */}
            </div>

            <ChangeLangueList/>
            {/* <button className={styles['navbar__account']}>{t('my_account')}</button> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MainNavBar 