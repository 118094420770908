import axiosConfig from 'features/axiosConfig'

async function submitSendmessage(messagedate) {
    let response = {data:[],status:false}
    try{
        let res = await axiosConfig.post(`/company/contact-us/`,messagedate)
        response.status=true;
        response.data = res.data.result
        return response
    }catch(e){
        response.data = e.message
        return response
    }
}

export default submitSendmessage