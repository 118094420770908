import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CompanyDescription.module.css'
function CompanyDescription() {
    const {t} =useTranslation()
    return (
    <div className={styles['preview-company__company-description']}>
        <h2 className={styles['preview-company__description-title']}>{t('description')}</h2>
        <p className={styles['preview-company__description-content']}>{t('description_content')}</p>
    </div>
  )
}

export default CompanyDescription