import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CategoriesFilter.module.css'
function CategoriesFilter({activeCategory , changeActiveCategory}) {
    const {t} = useTranslation()
    
  return (
    <div className={styles['categories__filter-cont']}>
        <ul className={styles['categories__filter-list']}>
            <li className={styles['categories__filter-item']}>
                <button className={`${styles['categories__filter-link']} 
                ${activeCategory ===1 &&styles['active']}`} onClick={()=>{changeActiveCategory(1)}}>{t('all')}</button>
            </li>
            <li className={styles['categories__filter-item']}>
                <button className={`${styles['categories__filter-link']} 
                ${activeCategory ===2 &&styles['active']}`} onClick={()=>{changeActiveCategory(2)}}>{t('commercial')}</button>
            </li>
            <li className={styles['categories__filter-item']}>
                <button className={`${styles['categories__filter-link']} 
                ${activeCategory ===3 &&styles['active']}`} onClick={()=>{changeActiveCategory(3)}}>{t('industrial')}</button>
            </li>
            <li className={styles['categories__filter-item']}>
                <button className={`${styles['categories__filter-link']} 
                ${activeCategory ===4 &&styles['active']}`} onClick={()=>{changeActiveCategory(4)}}>{t('agricultural')}</button>
            </li>
            <li className={styles['categories__filter-item']}>
                <button className={`${styles['categories__filter-link']} 
                ${activeCategory ===5 &&styles['active']}`} onClick={()=>{changeActiveCategory(5)}}>{t('services')}</button>
            </li>
        </ul>
    </div>
  )
}
export default CategoriesFilter