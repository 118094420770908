import React from 'react'
import DescHeader from 'components/Global/DescHeader/DescHeader'
import CompanyDetails from 'components/PreviewCompany/CompanyDetails/CompanyDetails'
import MainNavBar from 'components/Global/MainNavBar/MainNavBar'
import SideNavBar from 'components/Global/SideNavBar/SideNavBar'
import Footer from 'components/Global/Footer/Footer'
import {motion} from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

function PreviewCompany() {
  const {t} =useTranslation();
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{t('preview_company')}</title>
        </Helmet>
        <MainNavBar/>
        <SideNavBar/>
        <DescHeader/>
        <CompanyDetails/>  
        <Footer/>
      </motion.div>
    </>
  )
}

export default PreviewCompany