import React from 'react'
import styles from './PreviewCompanyInfo.module.css'

import src from 'assets/imgs/company-image.jpg'
import src2 from 'assets/imgs/company-logo.png'
import Rate from 'components/Global/Rate/Rate'
import { useTranslation } from 'react-i18next'
function PreviewCompanyInfo() {
    const {t} =useTranslation()

  return (
    <>
        <div className={styles['preview-company__company-img-cont']}>
            <img src={src} alt='company' className={styles['preview-company__company-img']}/>
        <div className={styles['preview-company__company-details']}>
            <img src={src2} alt='company' className={styles['preview-company__company-logo']}/>
            <div className={styles['preview-company__company-details-cont']}>
                <h1 className={styles['preview-company__name']}>{t('company_name')}</h1>
                <p className={styles['preview-company__cat']}>
                    <span className={styles['preview-company__cat-name']}>{t('categories')}</span>
                    <span className={styles['preview-company-cat-desc']}>{t('clothing')}</span>
                </p>
                <Rate rateNum="3"/>
            </div>
        </div>
        </div>

    </>
  )
}

export default PreviewCompanyInfo