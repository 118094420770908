import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/icons/logo-en.svg'
import styles from './Footer.module.css'
import { useTranslation } from 'react-i18next'
function Footer() {
    const {t} =useTranslation()
  return (
    <footer>
        <Container>
            <div className={styles['footer__top']}>
                <div className={styles['footer__list-cont']}>
                    <ul className={styles['footer__list']}>
                        <li className={styles['footer__item']}>
                            <NavLink to='/' className={styles['footer__link']}>{t('home')}</NavLink>
                        </li>
                        {/* <li className={styles['footer__item']}>
                            <NavLink to='/add-company' className={styles['footer__link']}>{t('add_company')}</NavLink>
                        </li> */}
                        {/* <li className={styles['footer__item']}>
                            <NavLink to='/categories' className={styles['footer__link']}>{t('categories')}</NavLink>
                        </li> */}
                        
                    </ul>
                    
                    <ul className={styles['footer__list']}>
                        {/* <li className={styles['footer__item']}>
                            <NavLink to='/about-us' className={styles['footer__link']}>{t('about_us')}</NavLink>
                        </li> */}
                        <li className={styles['footer__item']}>
                            <NavLink to='/contact-us' className={styles['footer__link']}>{t('contact_us')}</NavLink>
                        </li>
                        {/* <li className={styles['footer__item']}>
                            <NavLink to='/' className={styles['footer__link']}>{t('terms_conditions')}</NavLink>
                        </li> */}
                    </ul>
                    <ul className={styles['footer__list']}>
                        {/* <li className={styles['footer__item']}>
                            <NavLink to='/about-us' className={styles['footer__link']}>{t('about_us')}</NavLink>
                        </li> */}
                         <li className={styles['footer__item']}>
                            <NavLink to='/add-company' className={styles['footer__link']}>{t('add_company')}</NavLink>
                        </li>
                        {/* <li className={styles['footer__item']}>
                            <NavLink to='/' className={styles['footer__link']}>{t('terms_conditions')}</NavLink>
                        </li> */}
                    </ul>
                </div>

                <div className={styles['footer__info']}>
                    <Logo  className={styles['footer__info-logo']}/>
                    <p className={styles['footer__info-desc']}>{t('find_and_contact_company')}</p>
                </div>
            </div>
            <div className={styles['footer__bottom']}>
                <p className={styles['footer__copy']}>© LemonHint.com. All rights reserved. 2023</p>
                <p className={styles['footer__develop']}>Developed with ❤️ by <a className='text-decoration-none text-white' href="https://appzone-technology.com/" target="_blank" rel="noreferrer">Appzone Technology</a></p>
            </div>
        </Container>
    </footer>
  )
}

export default Footer